<template>
  <v-app>
    <v-card class="rounded-0 elevation-0">
      <v-toolbar
        dark
        tabs
        flat
        color="indigo"
        class="elevation-3 pr-2"
      >
        <v-img
          src="./assets/scaffolding-logo.webp"
          max-height="40"
          max-width="40"
          class="mr-2 ml-2"
        ></v-img>

        <v-toolbar-title v-if="!isMobile">SILVERCUPSCAFFOLDING | CRM <small>(PM's Portal)</small></v-toolbar-title>
        <v-toolbar-title v-if="isMobile">SILVERCUP |  <small>PM's CRM</small></v-toolbar-title>
        <v-spacer></v-spacer>

        <v-divider
          inset
          vertical
        ></v-divider>
        <v-tooltip left color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="$router.push('/crm-issues')"
            >
              <v-icon>mdi-bug</v-icon>
            </v-btn>
          </template>
          <span>Report Issue</span>
        </v-tooltip>
        <v-divider
          inset
          vertical
        ></v-divider>
        <v-tooltip left color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="$router.push('/profile')"
            >
              <v-icon>mdi-account-circle-outline</v-icon>
            </v-btn>
          </template>
          <span>Profile</span>
        </v-tooltip>
        <v-divider
          inset
          vertical
        ></v-divider>
        <v-tooltip left color="info">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              color="white"
              v-bind="attrs"
              v-on="on"
              @click="logout"
            >
              <v-icon>mdi-logout</v-icon>
            </v-btn>
          </template>
          <span>Logout</span>
        </v-tooltip>

        <template v-slot:extension>
          <v-tabs
            v-model="tabs"
            align-with-title
            next-icon="mdi-arrow-right-bold-box-outline"
            prev-icon="mdi-arrow-left-bold-box-outline"
            show-arrows
          >
            <v-tab to="/">
              Home
            </v-tab>
            <v-tab to="/jobs">
              Jobs
            </v-tab>
            <v-tab to="/order-submission">
              Order Submission
            </v-tab>
            <!-- <v-tab to="/upcoming-order-submission">
              Upcoming Order Submission
            </v-tab> -->
            <v-tab to="/job-schedules">
              Schedules
            </v-tab>
             <v-tab to="/incidents">
              Incidents
            </v-tab>
            <v-tabs-slider color="pink"></v-tabs-slider>
          </v-tabs>
        </template>
      </v-toolbar>

      <v-card-text>
        <v-main class="lighten-2 pa-0">
          <v-container fluid>
            <v-row>
              <v-col cols="12">
                <router-view></router-view>
              </v-col>
            </v-row>
          </v-container>
        </v-main>
      </v-card-text>

    </v-card>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    tabs   : null,
  })
};
</script>