import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login')
  },
  {
    path: '/',
    name: 'home',
    component: HomeView,
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/jobs',
    component: () => import('../views/Jobs'),
    meta : {
      requiresAuth : true
    },
    children : [
      {
        path: '/',
        component: () => import('../components/jobs/index.vue'),
        meta : {
          requiresAuth : true
        }
      },
      {
        path: 'detail/:id',
        name : 'JobDetails',
        component: () => import('../views/JobDetails'),
         meta : {
          requiresAuth : true
        }
      },
    ]
  },
  {
    path: '/order-submission',
    name: 'OrderSubmission',
    component: () => import('../views/OrderSubmission'),
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/order-submission/:id',
    name: 'OrderSubmissionSingle',
    component: () => import('../views/OrderSubmission'),
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/incidents',
    name: 'incidents',
    component: () => import('../views/Incidents.vue'),
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/job-schedules',
    name: 'JobSchedules',
    component: () => import('../views/Schedules.vue'),
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/profile',
    name: 'profile',
    component: () => import('../views/Profile.vue'),
    meta : {
      requiresAuth : true
    }
  },
  {
    path: '/crm-issues',
    name: 'CRMIssues',
    component: () => import('../views/CRMIssues.vue'),
    meta : {
      requiresAuth : true
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.USER_LOGGED_IN) {
      next({ name: 'Login' }) // go to Login Page
    } else {
      next() // go to wherever I'm going
    }
  } else {
    next() // does not require auth, make sure to always call next()!
  }
})

export default router
