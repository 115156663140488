<template>
  <div>

  <v-data-table
    :headers="headers"
    :items="items"
    sort-by="id"
    sortDesc
    class="elevation-1"
    :loading="loading"
    :search="search"
  >
    <template v-slot:top>
      <v-toolbar flat color="white">
        <v-toolbar-title>{{title}}</v-toolbar-title>
        <v-divider
          class="mx-4"
          inset
          vertical
          v-if="!isMobile || showDivider"
        ></v-divider>

        <slot name="top-right" v-if="!isMobile"></slot>
        <v-spacer v-if="!isMobile"></v-spacer>

        <v-row v-if="!isMobile">
          <v-col>
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
          </v-col>
        </v-row>
      </v-toolbar>

      <!-- Mobile Search Bar -->
      <v-divider v-if="isMobile"></v-divider>
      <div v-if="isMobile" class="px-2 my-2 justify-center text-center">
        <slot name="top-right"></slot>
      </div>
      <v-divider v-if="isMobile"></v-divider>
      <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        class="mx-5"
        v-if="isMobile"
      ></v-text-field>
      <v-divider v-if="isMobile" class="mt-3 mb-1"></v-divider>

    </template>

     <!-- SCHEDULE DATES ON POPOVER -->
    <template v-slot:item.schedule_dates="{ item }">
      <v-menu bottom offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn outlined x-small color="primary" v-bind="attrs" v-on="on" :disabled="item.schedule_dates == null">
            Click to View
          </v-btn>
        </template>

        <v-list v-if="item.schedule_dates" color="primary" dark>
          <v-subheader>SCHEDULE DATES</v-subheader>
          <v-divider></v-divider>
          <v-list-item
            v-for="(date, index) in item.schedule_dates.split(',')"
            :key="index"
            dense
            @click=""
          >
            <v-list-item-title>{{ date }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </template>
    <!-- #END SCHEDULE DATES POPOVER -->

    <!-- #is_completed slot -->
    <template v-slot:item.is_completed="{ item }">
      <v-icon v-if="item.is_completed == 1" color="success">mdi-check-circle</v-icon>
      <v-icon v-if="item.is_completed == 0 && item.schedule_dates != null" color="primary">mdi-clock-time-two</v-icon>
      <v-icon v-if="item.is_completed == 0 && item.schedule_dates == null" color="orange">mdi-progress-clock</v-icon>
    </template>
    <!-- #EDN is_completed slot  -->


    <!-- STATUS COLUMN SLOT -->
    <template v-slot:item.status="{ item }">
      <v-chip v-if="item.status" class="status-badge" label small :color="getColor(item.status)" dark>
        {{ item.status }}
      </v-chip>
    </template>


    <template v-slot:item.custom_column="{ item }">
      <slot name="custom-column" :item="item"></slot>
    </template>

    <!-- ACTIONS COLUMN SLOT -->
    <template v-slot:item.actions="{ item }">
      <v-icon
        small
        class="mr-2"
        @click="editItem(item)"
        color="primary"
        v-if="showEdit"
      >
        mdi-pencil
      </v-icon>
      <v-btn
        v-if="showView"
        @click="viewItem(item)"
        color="primary"
        icon
        x-small
        class="mr-2"
      >
        <v-icon>mdi-eye-circle</v-icon>
      </v-btn>
      <v-icon
        small
        @click="openDialog(item)"
        v-if="showDelete"
        color="red"
      >
        mdi-delete
      </v-icon>

      <v-btn
        v-if="showReRequest && item.status_id == 3"
        @click="reRequestItem(item)"
        color="info darken-1"
        icon
        x-small
      >
        <v-icon>mdi-repeat</v-icon>
      </v-btn>

    </template>
    <template v-slot:no-data>
      <!-- Default Message -->
    </template>
  </v-data-table>

  <!-- DELETE CONIRMATION POPUP -->
  <v-dialog v-model="dialog" persistent max-width="380">
    <v-card>
      <v-card-title class="subtitle">Are you sure, you want to delete it?</v-card-title>
      <v-card-text><center><small>It may impact the other data where it's being used.</small></center></v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary darken-1" text @click="dialog = false">No</v-btn>
        <v-btn color="red darken-1" text @click="deleteItem">Yes</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>

  </div>
</template>

<script>
export default {
  name : 'GeneralDataTable',
  props: {
    title : {
      type   : String,
      default : ''
    },
    headers : {
      type    : Array,
      default : () => { return [] }
    },
    items : {
      type    : [Array,Object], 
      default : () => {}
    },
    loading : {
      type    : Boolean,
      default : false 
    },
    showEdit : {
      type    : Boolean,
      default : false
    },
    showDelete : {
      type    : Boolean,
      default : false
    },
    showDivider : {
      type    : Boolean,
      default : false
    },
    showView : {
      type    : Boolean,
      default : false
    },
    showReRequest : {
      type    : Boolean,
      default : false
    },
    deleteUrl : {
      type     : String,
      default  : null
    },
  },
  data: () => ({
    search       : null,
    is_deleteing : false,
    dialog       : false,
    snack        : false,
    snack_color  : '',
    snack_text   : '',
    activeItem   : {},
  }),
  methods: {
    /**
    * @Description
    * 
    * 1. This method is used to get the Status Color for badge background
    * 2. It receives the status as a string and returns the appropriate color name
    * 
    * @param status { String }
    * @reutrn String 
    **/
    getColor (status) {
      switch(status){
        case 'Under Review':
        case 'New':
          return 'orange'
        case 'Sent':
          return 'deep-purple'
        case 'Completed':
        case 'Approved':
          return 'green'
        case 'Declined':
          return 'red'
        case 'Scheduled':
          return 'primary'
      }
    },
     /**
    * @Description
    * 
    * 1. This method is used to delete the entery from backend
    * 2. Since this is a shared common component, so this method is acting also dynamically for all endpoints
    * 3. If first close the confiration dialog, the Yes/No dialog  which was opend for the the confirmation
    * 4. Then it dispatches the HTTP Delete Request to backend
    * 5. If successfully deleted, then it show the success message and emits 'reload' to parent component
    * 6. The emit to parent is because, parent table have to reload the data after deleting a recordin
    * 
    * @params none
    * @return void
    **/
    deleteItem(){
      this.dialog       = false
      let item          = this.activeItem
      this.is_deleteing = item.id || item.ID
      
      this.$store.dispatch('HTTP_DELETE_REQUEST',`${this.deleteUrl}/${item.id || item.ID}`).then(response=>{
        if(response.message && response.message == 'success'){
          this.snack        = true
          this.snack_color  = 'success'
          this.snack_text   = `Item deleted successfully.`
          this.$emit('reload',true)
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.is_deleteing = false
      })
    },
    editItem (item) {
     this.$emit('edit',item)
    },
    viewItem (item){
      this.$emit('view',item)
    },
    reRequestItem (item){
      this.$emit('rerequest',item)
    },
    openDialog(item){
      this.activeItem = item
      this.dialog     = true
    },
  }
};
</script>

<style scopped>
.status-badge{
  width      : 75%;
  text-align : center;
}
@media only screen and (max-width: 600px) {
  .status-badge{
    width: 100%;
  }
}
</style>