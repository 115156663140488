<template>
  <div>
    <v-container fluid>
      <!-- TOP STATS BAR -->
      <v-row>
        <v-col cols="12" md="3">
          <!-- My Total Jobs -->
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            >
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article"
            ></v-skeleton-loader>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Total Jobs <v-icon>mdi-briefcase-outline</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1 ">
                  <small >{{top_bar_stats.total_jobs | padZeros}}</small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <!-- Closed Jobs -->
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            >
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article"
            ></v-skeleton-loader>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Closed Jobs <v-icon color="green">mdi-briefcase-check-outline</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1 green--text">
                  <small >{{top_bar_stats.closed_jobs | padZeros}}</small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <!-- Missing Daily Reports -->
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            >
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article"
            ></v-skeleton-loader>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Total UP Jobs <v-icon color="orange">mdi-thumb-up-outline</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1 orange--text">
                  <small >{{top_bar_stats.up_jobs | padZeros}}</small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="3">
          <!-- Total Violations  -->
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            >
            <v-skeleton-loader
              v-if="loading_top_bar"
              type="article"
            ></v-skeleton-loader>
            <v-list-item three-line v-else>
              <v-list-item-content>
                <div class="text-overline mb-4">
                  Total Violations <v-icon color="red">mdi-sign-caution</v-icon>
                </div>
                <v-list-item-title class="text-h5 mb-1 red--text">
                  <small >{{top_bar_stats.total_violations | padZeros}}</small>
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
      </v-row>
      <!-- RECENT JOBS -->
      <v-row>
        <v-col cols="12" md="8">
          <v-card
            class="mx-auto pa-3 elevation-1"
            outlined
            >
            <v-list-item three-line class="px-0">
              <v-list-item-content class="px-0">
                <div class="text-overline mb-4">
                 My Latest Scheduled Jobs
                </div>
                <div style="overflow-x:auto;">
                  <v-skeleton-loader
                    v-if="loading"
                    type="table-tbody"
                    height="250"
                  ></v-skeleton-loader>
                  <v-simple-table  height="280" dense fixed-header v-else>
                    <template v-slot:default>
                      <thead>
                        <tr>
                          <th class="text-left" v-if="!isMobile">ID</th>
                          <th class="text-left">Job</th>
                          <th class="text-left">Scheduled On</th>
                          <th class="text-left" v-if="!isMobile">Status</th>
                          <th class="text-center"><small>Report Sent?</small></th>
                          <th class="text-center"><small>Pictures Sent?</small></th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr v-for="item in recent_jobs" :key="item.name+item.date">
                          <td v-if="!isMobile">
                            <a :href="`/jobs/detail/${item.id}`" target="_blank" class="no-underline">
                              {{ item.id }}
                            </a>
                          </td>
                          <td>
                            <a :href="`/jobs/detail/${item.id}`" target="_blank" class="no-underline">
                              <small>{{ item.name }}</small>
                            </a>
                          </td>
                          <td>
                            {{ item.date }}
                          </td>
                          <td v-if="!isMobile">{{ item.status }}</td>
                          <td class="text-center">
                            <v-icon v-if="item.report_sent == 1" color="green">mdi-check-outline</v-icon>
                            <v-icon v-else-if="item.report_sent == 0" color="red">mdi-close-outline</v-icon>
                            <span v-else>N/A</span>
                          </td>
                          <td class="text-center">
                            <v-icon v-if="item.picture_sent == 1" color="green">mdi-check-outline</v-icon>
                            <v-icon v-else-if="item.picture_sent == 0" color="red">mdi-close-outline</v-icon>
                            <span v-else>N/A</span>
                          </td>
                        </tr>
                        <v-divider></v-divider>
                      </tbody>
                    </template>
                  </v-simple-table>
                </div>
              </v-list-item-content>
            </v-list-item>
          </v-card>
        </v-col>
        <v-col cols="12" md="4">
        
          <v-card
          class="mx-auto pa-3 elevation-1"
          outlined
          >
          <v-list-item three-line> 
            <v-list-item-content>
              <div class="text-overline mb-4">
                Job Schedules
              </div>
            </v-list-item-content>
          </v-list-item>
          
          <v-divider></v-divider>
            <div class="overflow-y-auto" style="height:262px">

              <v-skeleton-loader
                v-if="loading"
                type="list-item-two-line, article"
              ></v-skeleton-loader>

              <v-tabs
                v-else
                v-model="tab"
                :grow="!isMobile"
                show-arrows
                background-color="grey lighten-3"
              >
                <v-tabs-slider></v-tabs-slider>

                <v-tab key="tab-today" :href="`#tab-today`">
                  Today
                </v-tab>
                <v-tab key="tab-yesterday" :href="`#tab-yesterday`">
                  Yesterday
                </v-tab>
                <v-tab key="tab-tomorrow" :href="`#tab-tomorrow`">
                  Tomorrow
                </v-tab>
                <v-divider></v-divider>
                <v-tab-item key="tab-today" value="tab-today">
                  <v-card
                    flat
                  >

                    <div class="text-center mt-10" v-if="!loading_schedule && !job_schedules.today_schedule.length">
                      <v-icon x-large color="grey darken-3">mdi-human-handsup</v-icon>
                      <h3>No Job Scheuled For Today.</h3>
                    </div>
                    <div v-for="job in job_schedules.today_schedule" :key="job.id">
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{job.name}}</v-list-item-title>
                          <v-list-item-subtitle>
                            <a :href="`/jobs/detail/${job.id}`" target="_blank" class="grey--text no-underline">
                              #{{job.id}}
                            </a>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{job.date}}
                        </v-list-item-action>
                      </v-list-item>
                    </div>

                  </v-card>
                </v-tab-item>

                <v-tab-item key="tab-yesterday" value="tab-yesterday">
                  <v-card
                    flat
                  >
                    <div class="text-center mt-10" v-if="!loading_schedule && !job_schedules.yesterday_schedule.length">
                      <v-icon x-large color="grey darken-3">mdi-human-handsup</v-icon>
                      <h3>No Job Was Scheuled Yesterday.</h3>
                    </div>
                    <div v-for="job in job_schedules.yesterday_schedule" :key="job.id">
                      <v-divider></v-divider>
                      <v-list-item two-line :class="{'red--text' : (!job.report_sent || !job.picture_sent)}">
                        <v-list-item-content>
                          <v-list-item-title>{{job.name}}</v-list-item-title>
                          <v-list-item-subtitle>
                            <a 
                              :href="`/jobs/detail/${job.id}`" 
                              target="_blank" 
                              class="grey--text no-underline"
                            >
                              #{{job.id}}
                            </a>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{job.date}}
                        </v-list-item-action>
                      </v-list-item>
                    </div>

                  </v-card>
                </v-tab-item>
                <v-tab-item key="tab-tomorrow" value="tab-tomorrow">
                  <v-card
                    flat
                  >
                    
                    <div class="text-center mt-10" v-if="!loading_schedule && !job_schedules.tomorrow_schedule.length">
                      <v-icon x-large color="grey darken-3">mdi-human-handsup</v-icon>
                      <h3>No Job Scheuled For Tommorrow.</h3>
                    </div>

                    <div v-for="job in job_schedules.tomorrow_schedule" :key="job.id">
                      <v-divider></v-divider>
                      <v-list-item two-line>
                        <v-list-item-content>
                          <v-list-item-title>{{job.name}}</v-list-item-title>
                          <v-list-item-subtitle>
                            <a :href="`/jobs/detail/${job.id}`" target="_blank" class="grey--text no-underline">
                              #{{job.id}}
                            </a>
                          </v-list-item-subtitle>
                        </v-list-item-content>
                        <v-list-item-action>
                          {{job.date}}
                        </v-list-item-action>
                      </v-list-item>
                    </div>

                  </v-card>
                </v-tab-item>
              </v-tabs>
              </div>

          </v-card>
        </v-col>
      </v-row>

    </v-container>
  </div>
</template>

<script>
export default {
  name: 'HomeView',
  data : ()=>({
    tab              : null,
    loading          : false,
    loading_top_bar  : false,
    loading_schedule : false,
    recent_jobs      : [],
    job_schedules    : {
      today_schedule     : [],
      yesterday_schedule : [],
      tomorrow_schedule  : []
    },
    top_bar_stats    : {
      total_jobs       : 0,
      closed_jobs      : 0,
      up_jobs          : 0,
      total_violations : 0
    },
  }),
  mounted(){
    this.getTopBarStats()
    this.getRecentJobs()
    this.getJobSchedules()
  },
  methods:{
    /**
    * @Description
    * 
    * 1. This method is used to get the top row status
    * 2. get stats of the logged in PM and set the data for displaying in top bar
    * 
    * @params none
    * @return void
    **/
    getTopBarStats(){
      this.loading_top_bar = true
      this.$store.dispatch('HTTP_GET_REQUEST',`/dashboard/top-bar-stats/${this.$store.state.USER_DATA.user['ID']}`)
      .then(response=>{
        if(response.data){
          this.top_bar_stats = response.data
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.loading_top_bar = false
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to get the recent jobs stats, it gets the 10 most recent jobs from API
    * 2. It get the data from API and display in recent jobs tables 
    * 
    * @params none
    * @return void
    **/
    getRecentJobs(){
      this.loading = true
      this.$store.dispatch('HTTP_GET_REQUEST',`/dashboard/recent-jobs/${this.$store.state.USER_DATA.user['ID']}`)
      .then(response=>{
        if(response.data){
          this.recent_jobs = response.data
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.loading = false
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to get the Today, Yesterday & Tomorrow Job schedules
    * 2. It gets the data from API and displays in the Job schedules tabs 
    * 
    * @params none
    * @return void
    **/
    getJobSchedules(){
      this.loading_schedule = true
      this.$store.dispatch('HTTP_GET_REQUEST',`/dashboard/job-schedules/${this.$store.state.USER_DATA.user['ID']}`)
      .then(response=>{
        if(response.data){
          this.job_schedules = response.data
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.loading_schedule = false
      })
    }
  }
};
</script>

<style scoped>
.no-underline{
  text-decoration : none;
}
.no-underline:hover{
  text-decoration : underline;
  font-weight     : bold;
}
</style>