import {useDropbox} from '@/plugins/dropbox'

export default {
  
  methods : {
    /**
    * @Description
    * 
    * 1. This method is used to upload the documents/files on dropbox and save results in DB
    * 2. It gets the array/list of files and loop through to make the payload to upload on dropbos
    * 3. Once file is uploaded on dropbox, it gets the dropbox response and save the document in DB
    * 4. If saved then resolve the promise otherwise throw the error and rejects the promise
    * 
    * @param args { Array | Object }
    * @return Promise
    **/
    async uploadFilesOnDropbox(args){

      return new Promise(async (resolve,reject)=>{
        const dropbox = useDropbox()

        for (let i = 0; i < args.files.length; i++) {
          try {
            if (+args.parent_id === 0) {
              throw 'Document parent entity can not be undefined!'
            }

            const file  = args.files[i]
            let payload = {
              path     : `/${args.module.name}/${args.parent_id}/`,
              name     : file.name,
              contents : file
            }

            let result = await dropbox.uploadFile(payload)
            result     = result.result

            let saved = await this.addNewDocumentInDB(args,result,file)
            if (saved) { resolve(true) }

          }catch (error) {
            reject(error)
          }
          finally { }
        }
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to check if the same file with same response is already exists in database
    * 2. It takes the args and dropbox response of newly uploaded file
    * 3. If found in DB then returns the true otherwise return false
    * 
    * @param args { Object | Array }
    * @param result { mixed }
    * @return Promise
    **/
    checkIfdocumentAlreadyExists(args,result){
      return new Promise((resolve,reject)=>{
        let filter = {
          crm_entity_type_id : args.module?.id,
          parent_entity_id   : args.parent_id,
          dropbox_id         : result.id,
        }

        let url        = args.search_url ? args.search_url : `document/search`
        let dataToPost = { url : url , payload :  {'filter': filter} }

        this.$store.dispatch('HTTP_POST_REQUEST',dataToPost).then(response=>{
          if(response.data && response.data.length){
            resolve(true)
          }else{
            resolve(false)
          }
        })
        .catch(error=>{
          reject(error)
        })
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to save the document in database
    * 2. This is being used for both job pictures and documents
    * 3. it first checks if the file already exists then ignores and reutrns the success response (resolve the promise)
    * 4. If not exists then prepares the payload , either it's of Document of Job Picure
    * 5. Then sends the POST HTTP request to save the data in database
    * 6. If successfully saved then send success response, resolve the promise otherwise error (rejects the promise)
    * 
    * @param args { Array | Object }
    * @param result { mixed }
    * @param file { File Object }
    * @reutrn Promise
    **/
    async addNewDocumentInDB(args,result,file){
      return new Promise(async(resolve,reject)=>{

        let exists = await this.checkIfdocumentAlreadyExists(args,result)

        if (!exists) {
          let payload = {
            entity_type_id          : args.module.id,
            parent_entity_id        : args.parent_id,
            file_name               : file.name,
            dropbox_id              : result.id,
            dropbox_name            : result.name,
            dropbox_path_display    : result.path_display,
            dropbox_rev             : result.rev,
            dropbox_size            : result.size,
            dropbox_client_modified : result.client_modified,
            dropbox_server_modified : result.server_modified,
          }

          if (!args.post_url.includes('job-picture')) {
            payload.type_id = file.document_type_id
          }
          if (args.post_url.includes('job-picture')) {
            payload.job_id    = args.job_id
            payload.parent_id = args.parent_id
          }

          let url = args.post_url ? args.post_url : `document`
          this.$store.dispatch('HTTP_POST_REQUEST',{url : url , payload : payload}).then(response=>{
            if (response.message && response.message == 'success') {
              resolve(true)
            }
          })
          .catch(error=>{
            reject(error)
          })
        }else{
          resolve(true)
        }
      })   
    }
  }
}