import axios from 'axios'
import store from '../store'

const axiosInstance = axios.create({
  baseURL: process.env.VUE_APP_API_BASE_URL,
  headers: {
    'Content-Type': 'application/json',
  },
})

axiosInstance.interceptors.response.use(response => response.data)
axiosInstance.interceptors.request.use( config => {
  const token = store.state.USER_DATA  ? store.state.USER_DATA.authToken : undefined
  if (token) {
    config.headers.common.Authorization = `Bearer ${token}`
  }
    return config
  },
  (error) => { Promise.reject(error) } 
)

function createAxiosResponseInterceptor() {
  const interceptor = axiosInstance.interceptors.response.use(
    response => response,
    error => {
      console.log(error)
      // Reject promise if usual error
      if (error.response.status !== 401) {
        return Promise.reject(error)
      }
      let originalConfig = error.config

      if (originalConfig.url !== '/auth/login' && error.response) {
        if (error.response.status === 401) {
          // Access Token has expired
          axiosInstance.interceptors.response.eject(interceptor)

          return store.dispatch('HTTP_POST_REQUEST',{url:'/auth/refresh-token'})
          .then(response => {
            const newToken = response
            originalConfig.headers.Authorization = `Bearer ${newToken}`
            return axiosInstance(originalConfig)
          })
          .catch( err => {
            localStorage.clear()
            window.location.href= "/"
            Promise.reject(err)
          })
          .finally(createAxiosResponseInterceptor)
        }
      }
      return Promise.reject(error)
    }
  )
}

createAxiosResponseInterceptor()

export default axiosInstance