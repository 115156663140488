import { Dropbox } from 'dropbox'

export function useDropbox() {

  /**
  * @Description 
  * 
  * 1. This method is used to delete the file from dropbox
  * 2. IT gets the file path in param and use the dropbox sdk method filesDeleteV2 to delete file
  * 
  * @param filePath { String }
  * @return mixed
  **/
  const deleteFile = async filePath => {
    let result = undefined
    let dbx    = new Dropbox({ accessToken: process.env.VUE_APP_DROPBOX_ACCESS_TOKEN })
    result     = await dbx.filesDeleteV2({ path: filePath })

    return result
  }
  /**
  * @Description
  * 
  * 1. This method is used to download the raw file content from dropbox
  * 2. It uses the dropbox SDK filesDownload download method and the create the file blob and create the fule Object URL
  * 3. Then it create the a tag with download attribute and triggers the click
  *
  * @param filePath { String }
  * @param fileName { String }
  * @reutrn mixed
  **/
  const downloadFile = async (filePath, fileName) => {
    let data   = undefined
    let dbx    = new Dropbox({ accessToken: process.env.VUE_APP_DROPBOX_ACCESS_TOKEN })
    data       = await dbx.filesDownload({ path: filePath })
    data       = data.result
    const blob = data.fileBlob
    const url  = window.URL.createObjectURL(blob)
    const link = document.createElement('a')
    link.href  = url

    link.setAttribute('download', fileName || data.name)
    document.body.appendChild(link)
    link.click()
    link.remove()
    window.URL.revokeObjectURL(url)

    return data
  }
  /**
  * @Description
  * 
  * 1. This method is used to get the file blob from dropbox and use the filesDownload method of SDK
  * 2. It gets the resulr and return the only file blop
  * 
  * @param filePath { String }
  * @return blob | mixed
  **/
  const getFileBlob = async filePath => {
    let data = undefined;
    let dbx  = new Dropbox({ accessToken: process.env.VUE_APP_DROPBOX_ACCESS_TOKEN })
    data     = await dbx.filesDownload({ path: filePath })
    data     = data.result

    return data.fileBlob
  }

  /**
  * @Description
  * 
  * 1. This method is sed to upload the files on dropbos
  * 2. Sets the payload with overwrite mode (if same file already exists) it will overwrite 
  * 3. the uses the fiesUploda method of SDK to upload files on SDK
  * 
  * @param payload { Object }
  * @return mixed
  **/
  const uploadFile = async payload => {
    let dbx     = new Dropbox({ accessToken: process.env.VUE_APP_DROPBOX_ACCESS_TOKEN })
    let result  = await dbx.filesUpload({ path: payload.path + payload.name, contents: payload.contents, mode: 'overwrite' })
    
    return result
  }

  return {
    uploadFile,
    downloadFile,
    deleteFile,
    getFileBlob,
  }
}
