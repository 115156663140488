<template>
 <div>

  <v-snackbar v-model="snack" :timeout="3000" :color="snack_color">
    {{ snack_text }}
    <template v-slot:action="{ attrs }">
      <v-btn v-bind="attrs" text @click="snack = false">Close</v-btn>
    </template>
  </v-snackbar>

  <v-data-table
    :headers="headers"
    :items="documents_list"
    item-key="ID"
    :sort="false"
    group-by="type"
    :class="classes"
    show-group-by
    hide-default-header
    hide-default-footer
    :loading="loading"
  >

  <template v-slot:top>
    <v-toolbar flat color="white" v-if="title">
      <v-toolbar-title>{{title}}</v-toolbar-title>
      <v-divider
        class="mx-4"
        inset
        vertical
        v-if="!isMobile"
      ></v-divider>
      <v-spacer></v-spacer>
    </v-toolbar>

    <v-divider></v-divider>
    <v-row justify="center" class="pt-5 pb-5">
      <uploader
        :disable-label=true
        :loading="uploading"
        :reset="resetFiles"
        multiple
        @upload="getFiles"
      >
        
      </uploader>
    </v-row>
    <v-divider class="mb-5"></v-divider>
  </template>
  
  <template v-slot:item.name="{ item }">
    <v-icon small color="primary"> mdi-{{getFileIcon(item.name)}} </v-icon>&nbsp;
    <span>{{item.name}}</span>
  </template>

  <template v-slot:item.actions="{ item }">
    <v-btn v-if="!item.deleted" small icon color="primary" :loading="downloading == item.id" @click="downloadItem(item)">
      <v-icon>mdi-download-circle</v-icon>
    </v-btn>
    <v-btn v-if="!item.deleted" small icon color="primary" @click="previewItem(item)">
      <v-icon>mdi-eye-circle</v-icon>
    </v-btn>
    <v-btn v-if="!item.deleted" small icon color="red" :loading="deleting == item.id" @click="deleteItem(item)">
      <v-icon>mdi-delete</v-icon>
    </v-btn>
    <v-btn v-if="item.deleted" small icon color="info" :loading="deleting == item.id" @click="restoreItem(item)">
      <v-icon>mdi-backup-restore</v-icon>
    </v-btn>
  </template>

  </v-data-table>

  <!-- PREVIEW DIALOG -->
  <v-dialog
    v-model="dialog"
    :width="getWidth"
  >
    <v-card>
      <!-- LOADER -->
      <v-card-text v-if="loading_doc" class="text-center pa-5">
        Loading document. Please wait...!
        <v-progress-linear
          indeterminate
          color="primary"
          class="mb-0 mt-1"
        ></v-progress-linear>
      </v-card-text>

      <!-- PREVIEW FILES -->
      <img v-if="active_document.type == 'IMG' &&  !loading_doc " :src="active_document.url" class="dialog-img">

      <object v-if="active_document.type == 'PDF' &&  !loading_doc && !isMobile" :data="active_document.url" type="application/pdf" width="100%" height="550">
        <!-- <embed :src="active_document.url" type="application/pdf" /> -->
      </object>

      <!-- Mobile View PDF Viewer -->
      <vue-pdf-app style="height: 75vh;" 
        v-if="active_document.type == 'PDF' &&  !loading_doc && isMobile"
        :config="pdfViewerConfig"
        :pdf="active_document.url"
      >
      </vue-pdf-app>

      <v-card-actions>
        {{active_document.name}}
        <v-spacer></v-spacer>
        <v-divider vertical></v-divider>
        <v-btn
          color="primary"
          text
          @click="dialog = false"
          class="ml-2"
        >
          CLOSE
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <!-- #END - PREVIEW DIALOG -->

</div>
</template>

<script>

import VuePdfApp from 'vue-pdf-app'
import 'vue-pdf-app/dist/icons/main.css'


import { useDropbox } from '@/plugins/dropbox'

export default {
  components: {
    VuePdfApp,
  },
  props:{
    title :{
      type    : String,
      default : ''
    },
    classes : {
      type    : String,
      default : 'elevation-1'
    },
    entityId : {
      type    : [Number,String],
      default : null
    },
    parentId : {
      type    : [Number,String],
      default : null
    },
  },
  data () {
    return {
      loading        : false,
      dialog         : false,
      loading_doc    : false,
      snack          : false,
      snack_color    : '',
      snack_text     : '',
      files          : [],
      dropbox        : useDropbox(),
      downloading    : false,
      deleting       : false,
      uploading      : false,
      resetFiles     : false,
      documents_list : [],
      active_document : { name : null , url : null, type : null },
      headers : [
        {
          text: 'Document',value: 'name', align: 'start', groupable : false
        },
        { text: 'Actions', value: 'actions', align: 'right' },
      ],
      pdfViewerConfig : {
        secondaryToolbar : {
          secondaryOpenFile     : false,
          documentProperties    : false,
          secondaryViewBookmark : false
        }
      }
    }
  },
  computed: {
    /**
    * @Description
    * 
    * 1. This computed is used to get the document viewer dialog dynamic width based on doc type
    * 
    * @params none
    * @return Number
    **/
    getWidth(){
      switch(this.active_document.type){
        case 'IMG':
          return 600
        case 'PDF':
        case 'DOC':
          return '100%'
        default : 
          return 500
      }
    }
  },
  mounted(){
    if (this.entityId && this.parentId) {
      this.getData()
    }
  },
  methods : {
    /**
    * @Description
    * 
    * 1. This method is used to get the documents list from API
    * 2. It applies the filtered based on received props and send request to backend
    * 3. If the list/documents found then it maps the data to relevant variables
    * 
    * @params none
    * @return none
    **/
    getData(){
      let dataToPost  = {
        url     : `document/search`,
        payload :  {'filter':{'crm_entity_type_id' : this.entityId,'parent_entity_id': this.parentId}}
      }
      this.loading = true
      this.$store.dispatch('HTTP_POST_REQUEST',dataToPost).then(response=>{
        if(response.data){
          this.documents_list = response.data.map(d=>{
            return{
              id      : d['ID'],
              type    : d['Document Type'],
              name    : d['File Name'],
              path    : d['Dropbox Path'],
              deleted : d['Deleted']
            }
          })
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.loading = false
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used to Download the document from Dropbox to local disk
    * 2. It gets the file type and gets download the blob from dropbox
    * 3. after blob is downloaded, then as per file type id creates the ObjectURL
    * 4. then it creates the link <a> tag with <downloda> attribure and clicks it to download document
    * 5. In the end it shows the alert of document downloaded
    * 
    * @param item { Object }
    * @return void
    **/
    async downloadItem(item){
      this.downloading = item.id
      let url          = null
      let blob         = await this.dropbox.getFileBlob(item.path)
      let fileType     = this.getFileType(item.path)

      if (fileType == 'IMG') {
        url = window.URL.createObjectURL(new Blob([blob]))
      }
      if (fileType == 'PDF') {
        url = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }))
      }
      const link = document.createElement('a')
      link.href  = url

      link.setAttribute('download', item.name)
      document.body.appendChild(link)
      link.click()
      link.remove()
      window.URL.revokeObjectURL(url)

      this.snack       = true
      this.snack_color  = 'info'
      this.snack_text   = `Document [${item.name}] downloaded.`
      this.downloading = false
    },
    /**
    * @Description
    * 
    * 1. This method is used to Preview the document as by default just names of docs are listed
    * 2. It gets the file type and gets download the blob from dropbox
    * 3. After blob is downloaded, then as per file type id creates the ObjectURL
    * 4. After creating URL it sets the data in active_document variable to preview in dialog
    *
    * @params item { Object }
    * @return void
    **/
    async previewItem(item){
      this.dialog      = true
      this.loading_doc = true
      this.active_document.name = null

      let URL      = null
      let blob     = await this.dropbox.getFileBlob(item.path)
      let fileType = this.getFileType(item.path)

      if (fileType == 'IMG') {
        URL = window.URL.createObjectURL(new Blob([blob]))
      }
      if (fileType == 'PDF') {
        URL = window.URL.createObjectURL(new Blob([blob], { type: 'application/pdf' }))
      }
      this.active_document.name = item.name
      this.active_document.type = fileType
      this.active_document.url  = URL
      this.loading_doc          = false

    },
    /**
    * @Description
    * 
    * 1. This method is used to restore the deleted item from backend
    * 2. It sends the document id to restore the deleted documents
    * 
    * @param item { Object }
    * @return void
    **/
    restoreItem(item){
      this.deleting = item.id
      this.$store.dispatch('HTTP_PUT_REQUEST',{url: `document/${item.id}/restore`, payload: {}}).then(response=>{
        if(response.message && response.message == 'success'){
          this.snack       = true
          this.snack_color  = 'success'
          this.snack_text   = `Document restored successfully.`
          this.getData()
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.deleting = false
      })
    },
    /**
    * @Description
    * 
    * 1. This method is used soft delete the document
    * 2. This sends the API request to backend along with doc id 
    * 3. if deleted then shows the success message, if there is any error it shows the error
    * 
    * @params item { Object }
    * @return void
    **/
    deleteItem(item){
      this.deleting = item.id
      this.$store.dispatch('HTTP_DELETE_REQUEST',`document/${item.id}`).then(response=>{
        if(response.message && response.message == 'success'){
          this.snack       = true
          this.snack_color  = 'success'
          this.snack_text   = `Document deleted successfully.`
          this.getData()
        }
      })
      .catch(error=>{
        console.log(error)
      })
      .finally(f=>{
        this.deleting = false
      })
    },
    getFiles(files){
      this.files = files
      this.uploadDocuments()
    },
    /**
    * @Description
    * 
    * 1. This method is simply used to get the Module name based on Id
    * 2. Checks through swithc statement and returns the default value
    * 
    * @param {id | String | Number }
    * @return String
    **/
    getModuleName(id){
      switch(id.toString()){
        case '1':
          return 'Job'
        case '5':
          return 'Proposal'
        case '6':
          return 'Document'
        case '7':
          return 'Permit'
        case '8':
          return 'CompletionLetter'
        case '9':
          return 'Violations'
      }
    },
    /**
    * @Description
    * 
    * 1. This method is used to upload the document on Dropbox
    * 2. It sets the payload based on props received  and use the dropbox SDK to upload files
    * 3. If successfully uploaded the it shows the success message otherwise error
    * 
    * @params none
    * @return none
    **/
    uploadDocuments(){
      let payload = {
        module    : { name : this.getModuleName(this.entityId), id : this.entityId },
        parent_id : this.parentId,
        files     : this.files,
        post_url  : ''
      }

      this.uploading = true
      this.uploadFilesOnDropbox(payload).then(res=>{
        if(res && res == true){
          this.resetFiles  = true
          this.snack       = true
          this.snack_color  = 'success'
          this.snack_text   = `Document(s) Uploaded successfully.`
          this.getData()
        }
      })
      .catch(error=>{
        console.log(error)
        this.snack       = true
        this.snack_color  = 'error'
        this.snack_text   = error
      })
      .finally(f=>{
        this.uploading = false
      })
    }
  }
};
</script>

<style>
  .v-row-group__header>td .v-btn__content .mdi-close{
    display: none;
  }
  .pdf-app #thumbnailView {
    width: calc(100% - 0px) !important;
  }
</style>
<style scoped>
  .dialog-img{
    width  : 100%;
    height : 100%;
  }
</style>