import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './registerServiceWorker'
import vuetify from './plugins/vuetify'
import '@babel/polyfill'
import 'roboto-fontface/css/roboto/roboto-fontface.css'
import '@mdi/font/css/materialdesignicons.css'


/*Register Global Components*/
import GeneralDataTable from '@/components/shared/GeneralDataTable.vue'
Vue.component('general-datatable', GeneralDataTable)

import DocumentsViewer from '@/components/shared/DocumentsViewer.vue'
Vue.component('documents-viewer', DocumentsViewer)

import Uploader from '@/components/shared/Uploader.vue'
Vue.component('uploader', Uploader)

/*Register Global Mixins*/
import GeneralMixin from './mixins/GeneralMixin'
Vue.mixin(GeneralMixin)

import fileUploader from '@/mixins/FileUploader'
Vue.mixin(fileUploader)


Vue.config.productionTip = false

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
