import Vue from 'vue'
import Vuex from 'vuex'
import axios from '../plugins/axios'
import VuexPersistence from 'vuex-persist'

const vuexLocal = new VuexPersistence({
  storage: window.localStorage
})

axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    USER_LOGGED_IN : false,
    USER_DATA      : null,
    JOB_FILTER     : null
  },
  getters: {
  },
  mutations: {
    setLoggedinState(state,data){
      state.USER_LOGGED_IN = data
    },
    setUserData(state,data){
      state.USER_DATA = data
    },
    setJobFilter(state,data){
      state.JOB_FILTER = data
    }
  },
  actions: {
    HTTP_POST_REQUEST({commit,state},data)
    {
      return axios
      .post(
        data.url,
        data.payload
      )
      .then(response => {
        return response
      })
    },
    HTTP_PUT_REQUEST({commit,state},data)
    {
      return axios
      .put(
        data.url,
        data.payload
      )
      .then(response => {
        return response
      })
    },
    HTTP_GET_REQUEST({commit,state},url)
    {
      return axios
        .get(url)
        .then(response => { return response })
    },
    HTTP_DELETE_REQUEST({commit,state},url)
    {
      return axios
        .delete(url)
        .then(response => { return response })
    }
  },
  modules: {
  },
  plugins: [vuexLocal.plugin]
})
